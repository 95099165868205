const isSSR = (typeof window === 'undefined')
const ssrProtocol = 'http'
const ssrHostname = 'localhost'
const webProtocol = 'https'
const webHostname = 'www.ampparit.com'

const config = {
  // These variables will differ depending on whether the code is executing in browser or in Node SSR:
  protocol: isSSR ? ssrProtocol : webProtocol, // @todo delete
  hostname: isSSR ? ssrHostname : webHostname, // @todo delete
  apiUrl: isSSR ? `${ssrProtocol}://${ssrHostname}/api` : `${webProtocol}://${webHostname}/api`,
  appVersionUrl: isSSR ? `${ssrProtocol}://${ssrHostname}/clientversion` : `${webProtocol}://${webHostname}/clientversion`,

  // These will always point to the "real" url, so will be be same in SRR:
  siteUrl: `${webProtocol}://${webHostname}`,
  socket: `${webProtocol}://${webHostname}`,

  // Normal config stuff
  displayAds: true,
  displayCooperationBanner: true,
  defaultLocation: 14264, // Helsinki
  facebookAppId: '133363990059595',
  gtmContainerId: 'GTM-TQZQTWT',

  // These get turned in to webpack DefinePlugin constants. If you disable a feature
  // by setting it false, the whole npm library will be excluded from final js bundle.
  webpack: {
    enableSentryIO: true,
    enableReduxDevtools: false,
    enableWhyDidYouRender: false,
  },

  // See https://almamedia.atlassian.net/wiki/spaces/ALTU/pages/1418035254/Component+parameters
  almaTunnusConfig: {
    scope: 'openid profile email offline_access',
    realm: 'Almatunnus',
    domain: 'tunnus.almamedia.fi',
    audience: 'https://tunnus.almamedia.fi',
    selfService: 'https://itsepalvelu.almamedia.fi',
    clientID: '5rEguAmL88cIk7CsTXy3huAFzAWpqtwA',
    redirectUri: `${webProtocol}://${webHostname}/`,    
  },

  // See https://docs.sentry.io/platforms/javascript/configuration/options/
  sentry: {
    dsn: 'https://af232dbc502582b3980daedbee10a050@o4507293114695680.ingest.de.sentry.io/4507293123149904',
    sampleRate: 0.50,
    maxBreadcrumbs: 15,
  },  

  // See https://www.npmjs.com/package/universal-cookie
  // Httponly is false because client needs programmatic access to tempProfile cookie
  cookieOptions: {
    maxAge: 3600*24*365,
    httpOnly: false,
    secure: true,
    path: '/',
    sameSite: 'lax',
  },
}

module.exports = config